import React from "react";
import Input from "../../Primitives/input";
import {
  validateFullName,
  validateMobileNo,
  validateEmailId,
  validatePindcode,
  validateAddress,
  validateAddressStreet,
  validateAddressLandmark,
  validateCityDistrict,
  validateState,
  validateGSTIN,
  validateEmptyObject,
} from "../../../utils/validationManager";
import {
  cityStateAPI,
  addAddressAPI,
  updateAddressAPI,
  fetchGSTINAPI,
  storeId,
  userDataAPI,
} from "../../../../public/constants/constants";
import axios from 'axios';
import apiManager from "../../../utils/apiManager";
import {
  ADD_NEW_ADD,
  REQUIRED_FIELD,
  VALID_NAME,
  DEFAULT_ADDRESS,
  VALID_MOBILE_NO,
  ENTER_EMAIL_ID,
  VALID_CITY,
  VALID_STATE,
  VALID_PIN,
  VALID_ADD,
  EMPTY_GSTIN,
  INVALID_GSTIN,
  THREECHAR_REQ,
  THREECHAR_REQ_NEW,
} from "../../../constants/app/myAccountConstants";
import { ADDRESS_TYPE } from '../../../constants/app/checkoutConstants';
import appCookie from "../../../utils/cookie";
import GSTINModal from './GSTINModal';

class AddAddressForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputText_name:
        this.props.editAddressDataPro == undefined
          ? ""
          : this.props.editAddressDataPro.name,
      inputText_number:
        this.props.editAddressDataPro == undefined
          ? ""
          : this.props.editAddressDataPro.phoneNumber,
      inputText_alternate_number:
        this.props.editAddressDataPro == undefined
        ? ""
        : this.props.editAddressDataPro.alternatePhoneNumber,
      inputText_email:
        this.props.editAddressDataPro == undefined
          ? ""
          : this.props.editAddressDataPro.emailId,
      inputText_pincode:
        this.props.editAddressDataPro == undefined
          ? ""
          : this.props.editAddressDataPro.pincode,
      inputText_address1:
        this.props.editAddressDataPro == undefined
          ? ""
          : this.props.editAddressDataPro.address1,
      inputText_address2:
        this.props.editAddressDataPro == undefined
          ? ""
          : this.props.editAddressDataPro.address2,
      inputText_address3:
        this.props.editAddressDataPro == undefined
          ? ""
          : this.props.editAddressDataPro.address3,
      inputText_city:
        this.props.editAddressDataPro == undefined
          ? ""
          : this.props.editAddressDataPro.city,
      inputText_state:
        this.props.editAddressDataPro == undefined
          ? ""
          : this.props.editAddressDataPro.state,
      isSetAsDefault:
        this.props.editAddressDataPro == undefined
          ? ""
          : this.props.editAddressDataPro.isDefault,
      nickname:
        this.props.editAddressDataPro == undefined
          ? undefined
          : this.props.editAddressDataPro.nickName,
      gstin:
        this.props.editAddressDataPro == undefined
          ? undefined
          : this.props.editAddressDataPro.gstin,
          cin:
        this.props.editAddressDataPro == undefined
          ? undefined
          : this.props.editAddressDataPro.cin,
      addressType:
        this.props.editAddressDataPro == undefined
        ? ADDRESS_TYPE.shipping
        : this.props.editAddressDataPro.addressType,

      isGSTINModalOpen: false,
      gstinErrMsg: null,
      GSTINData: null,
      isGSTINApplied:
        this.props.editAddressDataPro == undefined
        ? false
        : !!((this.props.editAddressDataPro.gstin
          && this.props.editAddressDataPro.gstin !== '')),
      legalName:
        this.props.editAddressDataPro == undefined
        ? undefined
        : this.props.editAddressDataPro.legalName,

      error_name: false,
      error_number: false,
      error_alternate_number: false,
      error_email: false,
      error_pincode: false,
      error_address1: false,
      error_address2: false,
      error_address3: false,
      error_city: false,
      error_state: false,

      errorMessage_name: "",
      errorMessage_number: "",      
      errorMessage_alternate_number: "",
      errorMessage_email: "",
      errorMessage_pincode: "",
      errorMessaget_address1: "",
      errorMessaget_address2: "",
      errorMessaget_address3: "",
      errorMessage_city: "",
      errorMessage_state: "",

      isSaveBtnDisabled: false,
      userLogOnEmailId:"",
    };
    this.reference = React.createRef();
    this.handleInput = this.handleInput.bind(this);
    this.changeAddressFrom = this.changeAddress.bind(this);
  }

  changeAddress(editAddressDataPro) {
    this.setState({
      inputText_name: editAddressDataPro.name,
      inputText_number: editAddressDataPro.phoneNumber,
      inputText_alternate_number: editAddressDataPro.alternatePhoneNumber,
      inputText_email: editAddressDataPro.emailId,
      inputText_pincode: editAddressDataPro.pincode,
      inputText_address1: editAddressDataPro.address1,
      inputText_address2: editAddressDataPro.address2,
      inputText_address3: editAddressDataPro.address3,
      inputText_city: editAddressDataPro.city,
      inputText_state: editAddressDataPro.state,
      isSetAsDefault: editAddressDataPro.isDefault,
      nickname: editAddressDataPro.nickName,

      error_name: false,
      error_number: false,
      error_alternate_number: false,
      error_email: false,
      error_pincode: false,
      error_address1: false,
      error_address2: false,
      error_address3: false,
      error_city: false,
      error_state: false,

      errorMessage_name: "",
      errorMessage_number: "",
      errorMessage_alternate_number: "",
      errorMessage_email: "",
      errorMessage_pincode: "",
      errorMessaget_address1: "",
      errorMessaget_address2: "",
      errorMessaget_address3: "",
      errorMessage_city: "",
      errorMessage_state: "",

      isSaveBtnDisabled: false,
    });
    window.scrollTo(0, document.body.scrollHeight - 700);
    if (this.reference.current) {
      this.reference.current.focus();
      // this.reference.current.getInputDOMNode().focus();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.editAddressDataPro) {
      this.setState({
        inputText_name: nextProps.editAddressDataPro.name,
        inputText_number: nextProps.editAddressDataPro.phoneNumber,
        inputText_alternate_number: nextProps.editAddressDataPro.alternatePhoneNumber,
        inputText_email: nextProps.editAddressDataPro.emailId,
        inputText_pincode: nextProps.editAddressDataPro.pincode,
        inputText_address1: nextProps.editAddressDataPro.address1,
        inputText_address2: nextProps.editAddressDataPro.address2,
        inputText_address3: nextProps.editAddressDataPro.address3,
        inputText_city: nextProps.editAddressDataPro.city,
        inputText_state: nextProps.editAddressDataPro.state,
        isSetAsDefault: nextProps.editAddressDataPro.isDefault,
        nickname: nextProps.editAddressDataPro.nickName,
        gstin: nextProps.editAddressDataPro.gstin,
        addressType: nextProps.editAddressDataPro.addressType,
        isGSTINModalOpen: false,
        gstinErrMsg: null,
        GSTINData: null,
        isGSTINApplied:
          nextProps.editAddressDataPro == undefined
          ? false
          : !!((nextProps.editAddressDataPro.gstin
            && nextProps.editAddressDataPro.gstin !== '')),
        legalName:
          nextProps.editAddressDataPro == undefined
          ? undefined
          : nextProps.editAddressDataPro.legalName,

        error_name: false,
        error_number: false,
        error_alternate_number: false,
        error_email: false,
        error_pincode: false,
        error_address: false,
        error_address1: false,
        error_city: false,
        error_state: false,

        errorMessage_name: "",
        errorMessage_number: "",
        errorMessage_alternate_number: "",
        errorMessage_email: "",
        errorMessage_pincode: "",
        errorMessaget_address: "",
        errorMessaget_address1: "",
        errorMessage_city: "",
        errorMessage_state: "",

        isSaveBtnDisabled: false,
      });
      // window.scrollTo(0, document.body.scrollHeight - 700);
      if (this.reference.current) {
        this.reference.current.focus();
        // this.reference.current.getInputDOMNode().focus();
      }
    }
  }

  onSavebuttonClick(event) {
    if (event) event.preventDefault();

    let isProceed = true;
    if(this.state.isGSTINApplied == true)
    {
      if(!validateEmptyObject(this.state.inputText_name))
      {
        isProceed = false;
      this.setState({
        error_name: true,
        errorMessage_name: !this.state.inputText_name
          ? `${REQUIRED_FIELD}`
          : '',
      });
    }}
    else  if (!validateFullName(this.state.inputText_name)) {
      isProceed = false;
      this.setState({
        error_name: true,
        errorMessage_name: !this.state.inputText_name
          ? `${REQUIRED_FIELD}`
          : `${VALID_NAME}`,
      });
    }
    if (!validateMobileNo(this.state.inputText_number)) {
      isProceed = false;
      this.setState({
        error_number: true,
        errorMessage_number: !this.state.inputText_number
          ? `${REQUIRED_FIELD}`
          : `${VALID_MOBILE_NO}`
      });
    }
    if (this.state.addressType && !validateMobileNo(this.state.inputText_alternate_number) && this.state.inputText_alternate_number!='' ) {
      isProceed = false;
      this.setState({
        error_alternate_number: true,
        errorMessage_alternate_number: !this.state.inputText_alternate_number
          ? `${REQUIRED_FIELD}`
          : `${VALID_MOBILE_NO}`,
      });
    }
    if (!validateEmailId(this.state.inputText_email)) {
      isProceed = false;
      this.setState({
        error_email: true,
        errorMessage_email: !this.state.inputText_email
          ? `${REQUIRED_FIELD}`
          : `${ENTER_EMAIL_ID}`,
      });
    }
    if (!validatePindcode(this.state.inputText_pincode)) {
      isProceed = false;
       if(this.state.errorMessage_pincode != 'State does not match the GSTIN State')
      this.setState({
        error_pincode: true,
        errorMessage_pincode: !this.state.inputText_pincode
          ? `${REQUIRED_FIELD}`
          : `${VALID_PIN}`,
        inputText_pincode: null,
      });
    }
    if (!validateAddress(this.state.inputText_address1)) {
      isProceed = false;
      this.setState({
        error_address1: true,
        errorMessaget_address1: !this.state.inputText_address1
          ? `${REQUIRED_FIELD}`
          : `${VALID_ADD}`,
      });
    }
    if (!validateAddressStreet(this.state.inputText_address2)) {
      isProceed = false;
      this.setState({
        error_address2: true,
        errorMessaget_address2: !this.state.inputText_address2
        ? `${REQUIRED_FIELD}`
        : `${VALID_ADD}`,
      });
    }
    if (this.state.inputText_address3.length>0 && !validateAddressLandmark(this.state.inputText_address3)) {
      isProceed = false;
      if(this.state.inputText_address3){
      this.setState({
        error_address3: true,
        errorMessaget_address3: this.state.inputText_address3 ? `${VALID_ADD}` : '',
        });}
    }
    if (!validateCityDistrict(this.state.inputText_city)) {
      isProceed = false;
      this.setState({
        error_city: true,
        errorMessage_city: !this.state.inputText_city
          ? `${REQUIRED_FIELD}`
          : `${VALID_CITY}`,
      });
    }
    if (!validateState(this.state.inputText_state)) {
      isProceed = false;
      this.setState({
        error_state: true,
        errorMessage_state: !this.state.inputText_state
          ? `${REQUIRED_FIELD}`
          : `${VALID_STATE}`,
      });
    }
    if(this.state.inputText_address1.length>0 && (this.state.inputText_address1.trim().length<=2 || this.state.inputText_address1.trim().length>30)){
      isProceed = false;
      this.setState({
        error_address1: true,
        errorMessaget_address1: `${THREECHAR_REQ}`,
      });
    }
    if(this.state.inputText_address2.length>0 && (this.state.inputText_address2.trim().length<=2 || this.state.inputText_address2.trim().length>100)){
      isProceed = false;
      this.setState({
        error_address2: true,
        errorMessaget_address2: `${THREECHAR_REQ_NEW}`,
      });
    }
    if(this.state.inputText_address3.length>0 && (this.state.inputText_address3.trim().length<=2 || this.state.inputText_address3.trim().length>30)){
      isProceed = false;
      this.setState({
        error_address3: true,
        errorMessaget_address3: `${THREECHAR_REQ}`,
      });
    }
	 if(this.props.isFromServiceRequest != undefined) {
	if (!this.props.isFromServiceRequest) {
		if(!this.state.isGSTINApplied && this.state.gstin!==''){
		  isProceed = false;
		  this.setState({
			gstinErrMsg :"Please submit the GST first",
		  });
		}
	}}
    if (!isProceed) {
      return;
    }

    if (this.props.isFromServiceRequest) {
      this.props.onAddressChange({
        name: this.state.inputText_name,
        phone_number: this.state.inputText_number,
        alternate_phone_number: this.state.inputText_alternate_number,
        email_id: this.state.inputText_email,
        pincode: this.state.inputText_pincode,
        address1: this.state.inputText_address1,
        address2: this.state.inputText_address2,
        address3: this.state.inputText_address3,
        city: this.state.inputText_city,
        state: this.state.inputText_state,
        default: "false",
      });
    } else {
      this.addAddress();
    }
  }

  handleInput(value) {
    this.setState({
      error_name: false,
      error_number: false,
      error_alternate_number: false,
      error_email: false,
      error_pincode: false,
      error_address1: false,
      error_address2: false,
      error_address3: false,
      error_city: false,
      error_state: false,
    });
    switch (value.target.id) {
      case "fullName":
        return this.setState({
          inputText_name: value.target.value,
        });
      case "phoneNumber":
        return this.setState({
          inputText_number: value.target.value,
        });
      case "alternatePhoneNumber":
          return this.setState({
            inputText_alternate_number: value.target.value,
          });
      case "emailId":
        return this.setState({
          inputText_email: value.target.value,
        });
      case "pincode":
        return this.setState({
          inputText_pincode: value.target.value,
        });
      case "address1":
        return this.setState({
          inputText_address1: value.target.value,
        });
      case "address2":
        return this.setState({
          inputText_address2: value.target.value,
        });
      case "address3":
        return this.setState({
          inputText_address3: value.target.value,
        });
      case "cityDistrict":
        return this.setState({
          inputText_city: value.target.value,
        });
      case "state":
        return this.setState({
          inputText_state: value.target.value,
        });
      case "gstin":
        return this.setState({
          gstin: value.target.value.toUpperCase(),
        });
        case "cin":
          return this.setState({
            cin: value.target.value.toUpperCase(),
          });
      default:
        return null;
    }
  }

  addAddress() {
    if (this.props.fromRequestFor) {
      this.props.onUpdateActivity({
        addressID: "",
        nickName: "",
        name: this.state.inputText_name,
        phone_number: this.state.inputText_number,
        alternate_phone_number: this.state.inputText_alternate_number,
        phoneNumber: this.state.inputText_number,
        email_id: this.state.inputText_email,
        pincode: this.state.inputText_pincode,
        address1: this.state.inputText_address1,
        address2: this.state.inputText_address2,
        address3: this.state.inputText_address3,
        city: this.state.inputText_city,
        state: this.state.inputText_state,
        default: String(document.getElementById("checkbox").checked),
      });
      this.props.onCancel();
      return;
    }

    let APIURL = addAddressAPI;
    if (this.state.nickname !== undefined) {
      APIURL = updateAddressAPI + this.state.nickname;
    }

    this.setState({
      isSaveBtnDisabled: true,
    });
    const data = {
      name: this.state.inputText_name,
      phone_number: this.state.inputText_number,
      alternate_phone_number: this.state.inputText_alternate_number,
      email_id: this.state.inputText_email,
      pincode: this.state.inputText_pincode,
      address1: this.state.inputText_address1.replace(' & ',' and ').replace(' &', ' and ').replace('& ', ' and ').replace('&', ' and '),
      address2: this.state.inputText_address2.replace(' & ',' and ').replace(' &', ' and ').replace('& ', ' and ').replace('&', ' and '),
      address3: this.state.inputText_address3.replace(' & ',' and ').replace(' &', ' and ').replace('& ', ' and ').replace('&', ' and '),
      city: this.state.inputText_city,
      state: this.state.inputText_state,
      default: String(document.getElementById("checkbox").checked),
      addressType: this.state.addressType,
    };

    if(data.email_id == "" || data.email_id ==null){
      data.email_id =this.state.userLogOnEmailId;
    }

    if (this.state.isGSTINApplied) {
      data.gstin = this.state.gstin;
      data.cin = this.state.cin;
      data.legalName = this.state.legalName;
    }

    apiManager
      .post(APIURL, data)
      .then(response => {
        this.props.onUpdateActivity();
        this.props.onCancel({
          name: "",
          phoneNumber: "",
          alternatePhoneNumber: "",
          emailId: "",
          pincode: "",
          address1: "",
          address2: "",
          address3: "",
          city: "",
          state: "",
          isDefault: String(false),
        });
        this.setState({
          isSaveBtnDisabled: false,
        });
        if (this.state.isSetAsDefault) {
          appCookie.set(
            "pincode",
            this.state.inputText_pincode,
            365 * 24 * 60 * 60 * 1000
          );
          appCookie.set("pincodeUpdated", true, 365 * 24 * 60 * 60 * 1000);
        }
      })
      .catch(error => {
        this.setState({
          isSaveBtnDisabled: false,
        });
      });
  }

  pincodeFocusOut() {
    if (
      this.state.inputText_pincode !== "" &&
      this.state.inputText_pincode.length === 6
    ) {
      if (validatePindcode(this.state.inputText_pincode)) {
        this.getStateCityFromPincode();
      }
    } else {
      this.setState({
        inputText_city: "",
        inputText_state: "",
      });
    }
  }

  getStateCityFromPincode() {
    apiManager
      .get(cityStateAPI + this.state.inputText_pincode)
      .then(response => {
        if (this.state.isGSTINApplied
          && this.state.inputText_state != response.data.data.state) {
            this.setState({
              inputText_city: '',
              inputText_state: '',
              error_pincode: true,
              errorMessage_pincode: 'State does not match the GSTIN State',
            });
        }
        this.setState({
          inputText_city: response.data.data.city,
          inputText_state: response.data.data.state,
        });
      })
      .catch(error => {
        const errorData = error.response.data;
        const errorMessage = errorData.error.error_message;
        this.setState({
          inputText_city: "",
          inputText_state: "",
          error_pincode: true,
          errorMessage_pincode: errorMessage,
        });
      });
  }

  onSetAsDefaultChange() {
    this.setState({
      isSetAsDefault: !this.state.isSetAsDefault,
    });
  }

  closeAddNewAddress() {
    if (this.state.nickName !== undefined) {
      this.props.onCancel({
        name: "",
        phoneNumber: "",
        alternatePhoneNumber: "",
        emailId: "",
        pincode: "",
        address1: "",
        address2: "",
        address3: "",
        city: "",
        state: "",
        isDefault: false,
      });
    } else {
      this.props.onCancel({
        name: this.state.inputText_name,
        phoneNumber: this.state.inputText_number,
        alternatePhoneNumber: this.state.inputText_alternate_number,
        emailId: this.state.inputText_email,
        pincode: this.state.inputText_pincode,
        address1: this.state.inputText_address1,
        address2: this.state.inputText_address2,
        address3: this.state.inputText_address3,
        city: this.state.inputText_city,
        state: this.state.inputText_state,
        isDefault: false, // String(this.state.isSetAsDefault), //commented for GI-951
      });
    }
  }

  toggleAddressType = toggleAddressType => {
    const { addressType } = this.state;
    if (addressType === toggleAddressType) return;

    let newAddressType = ADDRESS_TYPE.shippingAndBilling;
    if (addressType === ADDRESS_TYPE.shippingAndBilling) {
      if (toggleAddressType === ADDRESS_TYPE.shipping)
        newAddressType = ADDRESS_TYPE.billing;
      else
        newAddressType = ADDRESS_TYPE.shipping;
    }
    this.setState({ addressType: newAddressType, gstin: '', cin: '' });
  };

  gstinModalReturnHandler = selectedName => {
    this.setState(prevState => ({ isGSTINModalOpen: !prevState.isGSTINModalOpen }));
    if (selectedName) {
      const { GSTINData, inputText_state } = this.state;
      if (GSTINData.state !== inputText_state) {
        this.setState({
          inputText_pincode: '',
          inputText_state: '',
          state: GSTINData.state,
        });
      }
      this.setState({
        inputText_name: selectedName,
        isGSTINApplied: true,
        legalName: GSTINData.legalName,
      });
    }
  };

  removeGstinDetails = () => {
    this.setState({
      isGSTINModalOpen: false,
      gstin: '',
      gstinErrMsg: null,
      GSTINData: null,
      isGSTINApplied: false,
      legalName: '',
    });
  };

  validateCIN(cin) {
    const regexCIN = /^([LU]{1})([0-9]{5})([A-Z]{2})([0-9]{4})([A-Z]{3})([0-9]{6})$/
  if (cin && cin !== 0) {
    if (!regexCIN.test(cin)) {
      return true;
    }
  }
  return false;
}


  async fetchGSTINDetails(gstInput, validateGST , submitBtn) {
    this.setState({ gstinErrMsg: null });
     if(!submitBtn){
      this.onSavebuttonClick()
    }else{
      return new Promise((resolve, reject) => {
        if (gstInput === '') {
          this.setState({
            gstinErrMsg: `${EMPTY_GSTIN}`,
          });
          return;
        }
        if (validateGSTIN(gstInput)) { // enter if the gst is invalid
          this.setState({
            gstinErrMsg: `${INVALID_GSTIN}`,
          });
          return;
        } else{
          if(gstInput[5] != 'C')
          {
            // do nothin
          }
          else if (this.validateCIN(this.state.cin) || this.state.cin == '' || this.state.cin == undefined) {
           this.setState({
              cinErrMsg: `Invalid CIN number`,
            });
            return;
          }else{this.setState({cinErrMsg:''})} 
        }
        apiManager
          .get(fetchGSTINAPI + gstInput)
          .then((response) => {
            if (response.data.data && response.data.data.status == 'Active') {
              if (!validateGST) {
                this.setState({
                  GSTINData: response.data.data,
                  isGSTINModalOpen: true,
                });
              }
              resolve();
            } else {
              if (!validateGST) {
                this.setState({
                  gstin: '',
                  gstinErrMsg: 'Your GST status is not active. Please proceed without GST or enter a different GST Number.​',
                });
              }
              reject('​GST number is not active. GST number must be active to avail a GST invoice');
           }
          })
          .catch(errorData => {
            const errorMessage = errorData.error.error_message;
           if (!validateGST) {
              this.setState({
                gstin: '',
                gstinErrMsg: errorMessage,
              });
            }
            reject(errorMessage);
          });
      });
    }
   };

  componentDidMount() {
    window.scrollTo(0, document.body.scrollHeight - 700);
    this.callprofileAPI();
  }

  callprofileAPI(){
    const token = appCookie.get('accessToken')
    axios.get(userDataAPI, {
      headers: { store_id: storeId, access_token: token },
    }).then(response => {
      if(response && response.data && response.data.data && response.data.data.emailID){
        this.setState({
        userLogOnEmailId:response.data.data.emailID,
        });
      }
    }).catch(error => {
      console.log("user detsils not found");
    })
  }

  onKeyPress = event => {
    if (event.key === "Enter") {
      if (!this.state.isSaveBtnDisabled) {
        this.onSavebuttonClick();
      }
    }
  };

  render() {
    const {
      isGSTINModalOpen,
      gstin,
      gstinErrMsg,
      isGSTINApplied,
      GSTINData,
      addressType,
    } = this.state;
    return (
      <>
        <div className="form-BgContainer addAddressContainer">
          <h4 className="heading">{ADD_NEW_ADD}{this.props.isFromServiceRequest && <span>*</span>}</h4>
          {this.props.isFromServiceRequest ? null : (
            <button
              className="cancelBtn"
              onClick={this.closeAddNewAddress.bind(this)}
            />
          )}

          <div className="row">
            <div className="col-md-6">
              <div className="form-div clearfix div-error">
                <Input
                  ref1={this.reference}
                  onKeyPress={this.onKeyPress}
                  inputType="text"
                  title="Full Name"
                  name="name"
                  id="fullName"
                  placeholder="Enter your name"
                  value={this.state.inputText_name}
                  handleChange={this.handleInput}
                  isAutoFocus
                  maxLength={64}
                  disabled={isGSTINApplied}
                />
                {this.state.error_name ? (
                  <div className="error-msg">{this.state.errorMessage_name}</div>
                ) : null}
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-div clearfix div-error">
                <Input
                  onKeyPress={this.onKeyPress}
                  inputType="number"
                  title="Phone Number"
                  name="number"
                  id="phoneNumber"
                  placeholder="Enter Number"
                  value={this.state.inputText_number}
                  handleChange={this.handleInput}
                  maxLength={10}
                />
                {this.state.error_number ? (
                  <div className="error-msg">
                    {this.state.errorMessage_number}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-div clearfix div-error">
                <Input
                  onKeyPress={this.onKeyPress}
                  inputType="email"
                  title="Email ID (Optional)"
                  name="email"
                  id="emailId"
                  placeholder="Enter Email ID"
                  value={this.state.inputText_email}
                  handleChange={this.handleInput}
                  // maxLength={30}
                />
                {this.state.error_email ? (
                  <div className="error-msg">{this.state.errorMessage_email}</div>
                ) : null}
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-div clearfix div-error">
                <Input
                  onKeyPress={this.onKeyPress}
                  inputType="text"
                  title="Pincode"
                  name="pincode"
                  id="pincode"
                  maxLength="6"
                  placeholder="Enter Pincode"
                  value={this.state.inputText_pincode}
                  handleChange={this.handleInput}
                  focusOut={this.pincodeFocusOut.bind(this)}
                />
                {this.state.error_pincode ? (
                  <div className="error-msg">
                    {this.state.errorMessage_pincode}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-div clearfix div-error">
                <Input
                  onKeyPress={this.onKeyPress}
                  inputType={"number"}
                  title={"Alternate Phone Number (Optional)"}
                  name={"alternateNumber"}
                  id={"alternatePhoneNumber"}
                  placeholder={"Enter Alternate Phone Number"}
                  value={this.state.inputText_alternate_number}
                  handleChange={this.handleInput}
                  maxLength={10}
                />
                {this.state.error_alternate_number ? (
                  <div className="error-msg">
                    {this.state.errorMessage_alternate_number}
                  </div>
                ) : null}
              </div>
            </div>
            
            <div className="col-md-12">
              <div className="form-div clearfix div-error">
                <Input
                  onKeyPress={this.onKeyPress}
                  inputType="text"
                  title="Address"
                  name="address1"
                  id="address1"
                  placeholder="Flat / House no / Floor / Building name"
                  value={this.state.inputText_address1}
                  handleChange={this.handleInput}
                  maxLength={30}
                />
                {this.state.error_address1 ? (
                  <div className="error-msg">
                    {this.state.errorMessaget_address1}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-div clearfix div-error">
                <Input
                  onKeyPress={this.onKeyPress}
                  inputType="text"
                  name="address2"
                  id="address2"
                  placeholder="Street name / Number / locality"
                  value={this.state.inputText_address2}
                  handleChange={this.handleInput}
                  maxLength={40}
                />
                {this.state.error_address2 ? (
                  <div className="error-msg">
                    {this.state.errorMessaget_address2}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-div clearfix div-error">
                <Input
                  onKeyPress={this.onKeyPress}
                  inputType="text"
                  name="address3"
                  id="address3"
                  placeholder="Landmark"
                  value={this.state.inputText_address3}
                  handleChange={this.handleInput}
                  maxLength={40}
                />
                {this.state.error_address3 ? (
                  <div className="error-msg">
                    {this.state.errorMessaget_address3}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-div clearfix div-error">
                <Input
                  inputType="text"
                  title="City/District"
                  name="city"
                  id="cityDistrict"
                  placeholder="Enter City / District"
                  value={this.state.inputText_city}
                  handleChange={this.handleInput}
                  readOnly
                />
                {this.state.error_city ? (
                  <div className="error-msg">{this.state.errorMessage_city}</div>
                ) : null}
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-div clearfix div-error">
                <Input
                  inputType="text"
                  title="State"
                  name="state"
                  id="state"
                  placeholder="Enter State"
                  value={this.state.inputText_state}
                  handleChange={this.handleInput}
                  readOnly
                />
                {this.state.error_state ? (
                  <div className="error-msg">{this.state.errorMessage_state}</div>
                ) : null}
              </div>
            </div>
          </div>
          {!this.props.isFromServiceRequest && (
            <div className="row">
              <div className="col-xs-6 col-md-6">
                <div className="defaultOption">
                  <div className="input_box">
                    <input
                      id="shippingChkBox" name="shippingChkBox"
                      className="checkbox inputCheck"
                      type="checkbox"
                      onChange={() => this.toggleAddressType(ADDRESS_TYPE.shipping)}
                      checked={addressType && addressType.includes(ADDRESS_TYPE.shipping)}
                    />
                    <label className="lblCheck" htmlFor="shippingChkBox" />
                  </div>
                  <label
                    htmlFor="shippingChkBox"
                    className="form-label defaultlbl"
                  >
                    Shipping Address
                  </label>
                </div>
              </div>
              <div className="col-xs-6 col-md-6">
                <div className="defaultOption">
                  <div className="input_box">
                    <input
                      id="billingChkBox" name="billingChkBox"
                      className="checkbox inputCheck"
                      type="checkbox"
                      onChange={() => this.toggleAddressType(ADDRESS_TYPE.billing)}
                      checked={addressType && addressType.includes(ADDRESS_TYPE.billing)}
                    />
                    <label className="lblCheck" htmlFor="billingChkBox" />
                  </div>
                  <label
                    htmlFor="billingChkBox"
                    className="form-label defaultlbl"
                  >
                    Billing Address
                  </label>
                </div>
              </div>
              <div className="col-xs-12 col-md-6">
                <div className="defaultOption">
                  <div className="input_box">
                    <input
                      className="defaultCheckbox inputCheck"
                      type="checkbox"
                      title="State"
                      name="name"
                      id="checkbox"
                      checked={this.state.isSetAsDefault}
                      onChange={this.onSetAsDefaultChange.bind(this)}
                    />
                    <label className="lblCheck" htmlFor="checkbox" />
                  </div>
                  <label className="defaultlbl" htmlFor='checkbox'>{DEFAULT_ADDRESS}</label>
                </div>
              </div>
            </div>
          )}
          {!this.props.isFromServiceRequest &&
            addressType && addressType.includes(ADDRESS_TYPE.billing) && (
              <div className="row">
                <div className="col-md-12 bussinessNote">
                  <h5 className="buying">Buying it for your business?</h5>
                  <div className="noteGstin">
                    <span className="bold">Note</span>
                    :The GSTIN cannot be changed once the order has been
                    placed. The state in which the GSTIN is registered must
                    be same either on the billing or the delivery address.
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-div clearfix div-error gstin-wrapper">
                    <Input
                      id="gstin" name="gstin"
                      inputType="text"
                      title="GSTIN (Optional)"
                      value={gstin}
                      handleChange={this.handleInput}
                    />
                     {isGSTINApplied ? (
                          <button
                            className="btn-blackbg"
                            onClick={this.removeGstinDetails}
                          >REMOVE</button>
                        ) : (
                          <button
                            className="btn-transbg"
                            onClick={this.fetchGSTINDetails.bind(this, gstin, false , true)}
                          >SUBMIT</button>
                        )}
                    <Input
                      id="cin" name="cin"
                      inputType="text"
                      title="CIN (Mandaroty if using GSTIN)"
                      value={this.state.cin}
                      handleChange={this.handleInput}
                    />
                    {this.state.cinErrMsg && <div className="error-msg">{this.state.cinErrMsg}</div>}
                   
                     {gstinErrMsg && <div className="error-msg">{gstinErrMsg}</div>}
                  </div>
                </div>
              </div>
          )}
          {this.props.isFromServiceRequest ? null : (
            <div className="col-md-12 add-new-btn">
              <div className="actionBtnWrapper">
                <button
                  onClick={this.closeAddNewAddress.bind(this)}
                  className="btn-cancel btn"
                >
                  CANCEL
                </button>
                <button
                  // onClick={this.onSavebuttonClick.bind(this)}
                  onClick={this.fetchGSTINDetails.bind(this, this.state.gstin, false , false)}
                 disabled={this.state.isSaveBtnDisabled}
                 className="btn-save btn"
                >
                  SAVE
                </button>
              </div>
            </div>
          )}
        </div>
        {isGSTINModalOpen && (
          <GSTINModal
            showGSTINModal={isGSTINModalOpen}
            GSTINDataPro={GSTINData}
            gstinModalReturnHandler={this.gstinModalReturnHandler.bind(this)}
          />
        )}
      </>
    );
  }
}

export default AddAddressForm;
