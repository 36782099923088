import React, { Component } from "react";
import "../../../public/styles/topfurniturecategories/offerScroll.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import apiManager from "../../utils/apiManager";
import { espotAPI } from "../../../public/constants/constants";

export default class OfferScroll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      espotName: "GI_OffersScroll",
      closed: false,
      offers: [],
    };
  }

  getEspotData() {
    apiManager
      .get(espotAPI + this.state.espotName)
      .then(response => {
        this.setState({
          offers: response.data.data,
        });
      })
      .catch(error => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  }

  componentDidMount() {
    this.getEspotData();
  }

  closeOffer = () => {
    this.setState({
      closed: !this.state.closed,
    });
  };

  render() {
    const { closed, offers } = this.state;
    const settings = {
      dots: false,
      lazyLoad: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      arrows: false,
      autoplaySpeed: 5000,
    };

    // if (offers && offers.length <= 0 || offers == "") return <></>;
    return (
      <>
      {offers && offers!='' && offers!="" && offers!=[] && offers!=null && offers!=undefined &&  offers.length > 0 &&
      <div
        className={
          closed ? "offerHeader-cotainer-closed" : "offerHeader-cotainer"
        }
      >
        <div className="offerHeader-wrapper">
          <div className="data-wrapper">
            <Slider {...settings} ref={slider => (this.slider = slider)}>
              {offers &&
                offers.map((off, key) => <p>{off}</p>)}
            </Slider>
          </div>
          <div
            className="offerClose-wrapper"
            onClick={this.closeOffer.bind(this)}
          >
            X
          </div>
        </div>
      </div>
      }
      </>
    );
  }
}
