import React from "react";
import apiManager from "../../utils/apiManager";
import "../../../public/styles/headerContainer/category.scss";
import SearchBar from "../Search/search";
import HeaderRight from "../HeaderRight/headerRight";
import {
  navigationApi,espotAPI,imagePrefix
} from "../../../public/constants/constants";
import SubCategoriesData from "../SubCategories/subCategories";
import {
  createCategoryPlpURL,
} from "../../utils/utilityManager";
import { Link, withRouter } from "react-router-dom";

class Category extends React.Component {
  state = {
    category: null,
    isLoading: true,
    errors: null,
    currentIndex: -1,
    isSearchActive: false,
    espotName: "GI_PDP_ProductConfigurator_Categories",
    displayProductConfiguratorCategories:'',
    productConfiguratorCategoryName:'',
    productConfiguratordescription:'',
    productConfiguratorCategories:[],
    showProductConfiguratorDiv:false,
    productonfiguratorImage:'',
    furniturelocations:null,
  };

  constructor() {
    super();
    this.currentMenu = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside(event) {
    if (event.target.id == "menu_title") {
      return;
    }
    if (
      this.currentMenu &&
      this.currentMenu.current.contains(event.target) &&
      this.lastIndex != -1
    );
    {
      this.onCloseMenu();
      document.removeEventListener("touchstart", this.handleClickOutside);
      event.preventDefault();
    }
  }

  onTouchAndMouserEvennt(event, index) {
    if (this.lastIndex != index) {
      if (event.type == "touchstart") {
        if (event.target.nodeName == "A") {
          this.lastIndex = index;
          this.setState({ currentIndex: index });
          document.addEventListener("touchstart", this.handleClickOutside);
        } else {
          return;
        }
      }
      this.lastIndex = index;
      this.setState({ currentIndex: index });
      // document.addEventListener('touchstart', this.handleClickOutside);
    }
  }

  onMouseOverOverMenu(event, parentId) {
    const pNode = document.getElementById(parentId);
    const cNode = event.relatedTarget;
    const flag = this.checkParent(pNode, cNode);

    if (flag == false && pNode != cNode) {
      this.onCloseMenu();
    }
  }

  showProductConfiguratorDiv(event) {
    this.setState({ showProductConfiguratorDiv: true });
  } 
  hideProductConfiguratorDiv(event,parentId) {
    const pNode = document.getElementById(parentId);
    const cNode = event.relatedTarget;
    const flag = this.checkParent(pNode, cNode);
    if (flag == false && pNode != cNode) {
    this.setState({ showProductConfiguratorDiv: false });
    }
  } 
  checkParent(parent, child) {
    let node = child.parentNode;
    while (node != null) {
      if (node == parent) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  }

  onCloseMenu(event) {
    this.lastIndex = -1;
    this.setState({ currentIndex: -1 });
  }

  onLinkNavigation = () => {
    appCookie.set("compareProductTemp", "", 365 * 24 * 60 * 60 * 1000);
    this.props.plpReduxStateReset();
    this.onCloseMenu();
    // this.setState({menuHidden:false});
    // setTimeout(function() {
    //   this.setState({menuHidden:true})
    // }.bind(this), 500)
  };

  getHeaderLayer2() {
    apiManager
      .get(navigationApi)
      .then(response => {
        const { data } = response || {};
        this.setState({
          category: data && data.data.categoryArray,
          isLoading: false,
        }
      //  ,()=>{ this.setupHeaderOrder()}
        );
        // Find the JSON object with categoryIdentifier: "sets"
        const locationsJson = data.data.categoryArray.find(json => json.categoryIdentifier === "locations");

        // Update the state with the found JSON object
        if (locationsJson) {
          this.setState({ 
            furniturelocations:locationsJson,
          });
        }
        
     })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  

  componentDidMount() {
    this.getHeaderLayer2();
    this.getProductConfiguratorEspotData();
  }

  getProductConfiguratorEspotData() {
    apiManager
      .get(espotAPI + this.state.espotName)
      .then(response => {
        const {data} = response || {};
        this.setState({
          displayProductConfiguratorCategories: data && data.data.displayProductConfiguratorCategories,
          productConfiguratorCategoryName: data && data.data.productConfiguratorCategoryName,
          productConfiguratordescription: data && data.data.productConfiguratordescription,
          productConfiguratorCategories: data && data.data.productConfiguratorCategories,
          productonfiguratorImage: data && data.data.productonfiguratorImage,
        });
      })
      .catch(error => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  }

  onSearchClick(flag) {
    if (flag) {
      return;
    }
    this.setState({
      isSearchActive: !this.state.isSearchActive,
    });
  }

  render() {
    const { category = [] } = this.state;
    return (
      <div className="category">
            
        
        <ul className="categoryList">
          {!!category &&
            category.map((categoryData, index) => (
              <>
                {categoryData.categoryIdentifier!='locations' && categoryData.categoryIdentifier!='offers' ? 
                <li
                  className="listItems"
                  key={`category-${index}`}
                  id={categoryData.categoryName + index}
                  onMouseOut={event => {
                    this.onMouseOverOverMenu(
                      event,
                      categoryData.categoryName + index
                    );
                  }}
                >
                  <a
                    className="action action-menu"
                    href={categoryData.categoryIdentifier=='sets'? '/furniture-sets' :categoryData.onClickUrl}
                    onMouseOver={event => {
                      this.onTouchAndMouserEvennt(event, index);
                    }}
                    onTouchStart={event => {
                      this.onTouchAndMouserEvennt(event, index);
                    }}
                    id="menu_title"
                  >
                    {`${categoryData.categoryName } `}{!!categoryData.subCategoryArray && categoryData.categoryIdentifier!='sets' && ( <span>
                      <img
                        src={require('../../../public/images/nav_next.svg')}
                        alt='nxtImg'
                      />
                    </span>)}
                  </a>
                  {!!categoryData.subCategoryArray && categoryData.categoryIdentifier!='sets' && (
                    <div>
                      <SubCategoriesData
                        reference={
                          this.state.currentIndex == index
                            ? this.currentMenu
                            : null
                        }
                        onCloseMenu={this.onCloseMenu.bind(this)}
                        isOpen={this.state.currentIndex == index}
                        subCategoryArray={categoryData.subCategoryArray}
                        categoryNamePro={categoryData.categoryIdentifier}
                      />
                    </div>
                  )}
                </li>
                :
                categoryData.categoryIdentifier=='offers' ?
                <li
                  className="listItems"
                  key={`category-${index}`}
                  id={categoryData.categoryName + index}
                  onMouseOut={event => {
                    this.onMouseOverOverMenu(
                      event,
                      categoryData.categoryName + index
                    );
                  }}
                >
                  <a
                    className="action action-menu"
                    href={categoryData.onClickUrl}
                    onMouseOver={event => {
                      this.onTouchAndMouserEvennt(event, index);
                    }}
                    onTouchStart={event => {
                      this.onTouchAndMouserEvennt(event, index);
                    }}
                    id="menu_title"
                  >
                    {`${categoryData.categoryName } `}{!!categoryData.subCategoryArray && categoryData.categoryIdentifier!='sets' && ( <span>
                      <img
                        src={require('../../../public/images/nav_next.svg')}
                        alt='nxtImg'
                      />
                    </span>)}
                  </a>
                  {!!categoryData.subCategoryArray && categoryData.categoryIdentifier!='sets' && (
                    <div>
                      {/* <SubCategoriesData
                        reference={
                          this.state.currentIndex == index
                            ? this.currentMenu
                            : null
                        }
                        onCloseMenu={this.onCloseMenu.bind(this)}
                        isOpen={this.state.currentIndex == index}
                        subCategoryArray={categoryData.subCategoryArray}
                        categoryNamePro={categoryData.categoryIdentifier}
                      /> */}
              <div
        className={this.state.currentIndex == index ? "catNav" : "catNavToggle"}
        ref={this.state.currentIndex == index
          ? this.currentMenu
          : null}
      >
        <ul
          className={categoryData.subCategoryArray.length > 6 ? "catLongList" : "catList"}
         
        >
              {categoryData.subCategoryArray && categoryData.subCategoryArray.map((subCategoryData, index) => {
                let routePath;
                const subcatName = String(subCategoryData.categoryName).toLowerCase();
                routePath = createCategoryPlpURL(
                  subCategoryData.categoryIdentifier
                );

                    return  <div className="navColumn">
                        <li className="subCatList l2Item" key={`subCat-${index}`}>
                          <Link
                            to={{
                              pathname: routePath,
                              state: { categoryId: subCategoryData.uniqueID },
                            }}
                            className="links"
                            onClick={this.onLinkNavigation}
                          >
                            {/* l2  */}
                            {subCategoryData.categoryName}
                          </Link>
                        </li>
                      </div>

                      }
                      )
                      }
                      {this.state.furniturelocations && this.state.furniturelocations.categoryIdentifier &&
                      
                      <div className="navColumn">
                        <li className="subCatList l2Item" key={`subCat-${index}`}>
                        <a
                    className="links"
                    href={this.state.furniturelocations.categoryIdentifier=='locations'? '/furniture-locations' :'furniture-'+furniturelocations.categoryIdentifier}
                    onMouseOver={event => {
                      this.onTouchAndMouserEvennt(event, index);
                    }}
                    onTouchStart={event => {
                      this.onTouchAndMouserEvennt(event, index);
                    }}
                    // id="menu_title"
                  >
                    {/* {'Furniture Locations 1'} */}
                    {`${this.state.furniturelocations.categoryName.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ') } `}
                    </a>
                          </li></div>

                  
                      }
                </ul>
                </div>
                    </div>
                  )}
                {/* {this.state.furniturelocations && 
                <a
                    className="action action-menu"
                    href={this.state.furniturelocations.categoryIdentifier=='sets'? '/furniture-sets' :'furniture-'+furniturelocations.categoryIdentifier}
                    onMouseOver={event => {
                      this.onTouchAndMouserEvennt(event, index);
                    }}
                    onTouchStart={event => {
                      this.onTouchAndMouserEvennt(event, index);
                    }}
                    id="menu_title"
                  >
                    {`${this.state.furniturelocations.categoryName } `}
                  </a>
                  } */}


                </li>
                :
                ''
                }
                {categoryData.categoryName == "Rooms" ? (
                  <>
                      <li className="listItems custom-links-home" id="CUSTOMLINKS">
                      <a className="action action-menu" href="/home-interiors">
                          Home Interiors
                      </a>
                    </li>
                    <li className="listItems custom-links-home" id="CUSTOMLINKS">
                      <a className="action action-menu" target="_blank" href="/kitchens">
                        Kitchens
                      </a>
                    </li>
                    
                    { this.state.displayProductConfiguratorCategories=="Yes" ?
                    <li className="listItems listItemsProductConfigurator" id={this.state.productConfiguratorCategoryName}
                    onMouseOut={event => {
                      this.hideProductConfiguratorDiv(event,this.state.productConfiguratorCategoryName);
                    }}
                    >
                      <a className="action action-menu action-menu-product-configurator" 
                      onMouseOver={event => {
                        this.showProductConfiguratorDiv(event);
                      }}
                      onTouchStart={event => {
                        this.showProductConfiguratorDiv(event);
                      }}
                      >
                        {this.state.productConfiguratorCategoryName}
                        <span> <img
                        src={require('../../../public/images/nav_next.svg')}
                        alt='nxtImg'
                      /> </span>
                      
                      </a>
                      {this.state.showProductConfiguratorDiv == true?
                      <div className="configurable-products"
                      
                      >
                      <div class="catNav">
                      <ul class="catList catList-products"
                      >
                      <div class="navColumn">
                      <li class="subCatList l2Item">
                      <ul class="newnavlist">
                      {this.state.productConfiguratorCategories.map((confData, index) => (
                      <li class="newnavlistitem">
                        {confData.mainCategoryurl!=''?
                        <a class="links links-new" href={confData.mainCategoryurl}>
                          <b>{confData.mainCategoryText}</b>
                        </a>
                        :<b class="links links-new">{confData.mainCategoryText}</b>
                        }
                        <ul>
                        {confData.subcategories.map((subCat, index) => (
                          <>
                          <li class="newnavlistitem">
                            <a class="links links-new" href={subCat.link}>{subCat.displayText}</a>
                          </li>
                          </>
                        ))}
                        </ul>
                      </li>
                      ))}
                        </ul>
                      </li>
                      <div className="productConfiguratordescriptiondiv">
                      {/* <span className="productConfiguratordescription">{this.state.productConfiguratordescription}</span> */}
                      <img
                        src={imagePrefix+this.state.productonfiguratorImage}
                        alt='nxtImg'
                      />
                      </div>
                      </div>
                      </ul>
                      </div>
                      </div>
                      :''}
                    </li>
                    :''}

                    {/* <li className="listItems custom-links-home" id="CUSTOMLINKS">
                      <a className="action action-menu" href="/blog">
                        Blogs
                      </a>
                    </li> */}
                      
                  </>
                ) : (
                  <></>
                )}
              </>
            ))}
        </ul>
        <SearchBar onSearchClick={this.onSearchClick.bind(this)}  isSearchActive={this.state.isSearchActive} />
        <HeaderRight onSearchClick={this.onSearchClick.bind(this)} isSearchActive={this.state.isSearchActive} />

      </div>
    );
  }
}

export default Category;
