export const NavForSEO = {
    '1-seater-sofas': '',
    '2-seater-sofas': '',
    '3-seater-sofas': '',
    'king-size-beds':'',
    'queen-size-beds': '',
    'latex-mattresses': '',
    'coir-mattresses': '',
    'wardrobe-design': '',
    'sofa-design': '',
    'bed-design': ''
}
